import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Layout = ()=>{
    return (

        <Container fluid>
  <Row>
    <Col></Col>
  </Row>
</Container>
    )
}

export default Layout;
